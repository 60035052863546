import React from 'react';
import Button from '../../../App/Components/Button/Button';
import {
  dataTimeFormat,
  dateFormat,
  executeText,
} from '../../../App/Utilities/Utilities';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import { ExportExcel } from '../../../App/Components/Excelexport/Excelexport';
import Checkbox from '../../../App/Components/Checkbox/Checkbox';
import { actionConfigs } from './actionConfigs';
import Input from '../../Input/Input';
import DateComponent from '../../components/dateComponent/DateComponent';

const CalculationDataAction = ({
  calculationData,
  query,
  setQuery,
  dataToExcel,
  onExecuteHandler,
  salaryEntries,
  resetTableData,
}) => {
  let dropDownOptions = [];
  const handleDropdownChange = (selectedOption, config) => {
    const { optionName, optionID } = config || {};
    resetTableData();
    const option = selectedOption || { value: '', id: null };

    setQuery((prevQuery) => ({
      ...prevQuery,
      [optionName]: option.value,
      [optionID]: option.id,
    }));
  };

  const handleDateChange = (date, name) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: dataTimeFormat(date),
    }));
  };

  const handleInputChange = (event, name) => {
    const { value } = event.target;
    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: value,
    }));
  };
  const handleCheckboxChange = (event, name) => {
    const { checked } = event.target;
    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: checked,
    }));
  };
  return (
    <SearchBackground>
      <div className="L-calculation-data-action-wrap-flex">
        <div className="L-calculation-filed">
          {actionConfigs?.map((config, index) => {
            if (typeof config.Children === 'function') {
              if (calculationData) {
                dropDownOptions = config.Children(
                  calculationData[config.optionName],
                );
              }
            }
            return (
              <div className="L-calculation-box" key={index}>
                {config.type === 'dropdown' && (
                  <SelectControl
                    value={{ label: query[config.optionName] }}
                    selectBoxTitle={config.title}
                    options={dropDownOptions || []}
                    onChange={(selectedOption) =>
                      handleDropdownChange(selectedOption, config)
                    }
                    requiredFiled={config.required}
                  />
                )}

                <div className="calendar-wrap">
                  {config.type === 'calendar' && (
                    <p className="G-title-p">{config.title}</p>
                  )}
                  <div className="G-flex">
                    {config.calendars?.map((i, calendarIndex) => (
                      <div
                        className="calendar-box"
                        key={`${index}-${calendarIndex}`}
                      >
                        <DateComponent
                          value={dateFormat(query[i.name])}
                          onChange={(date) => handleDateChange(date, i.name)}
                          name={i.name}
                          requiredFiled={false}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                {config.type === 'input' && (
                  <div className="input-control">
                    <Input
                      type="text"
                      inputTitle={config.title}
                      name={query[config.name]}
                      requiredFiled={config.required}
                      onChange={(e) => handleInputChange(e, config.name)}
                      className="has-border"
                    />
                  </div>
                )}
                {config.type === 'checkbox' && (
                  <div key={index}>
                    <Checkbox
                      name={String(config.name)}
                      label={config.title}
                      checked={query[config.name]}
                      onChange={(e) => handleCheckboxChange(e, config.name)}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="L-calculation-btn">
          <div className="G-add-btn">
            <Button
              type="button"
              text={executeText}
              onClick={onExecuteHandler}
            />
          </div>
          {salaryEntries?.length > 0 && (
            <ExportExcel
              excelData={dataToExcel}
              fileName="Հաշվարկային տվյալների դիտում"
            />
          )}
        </div>
      </div>
    </SearchBackground>
  );
};

export default CalculationDataAction;
