import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import TopArrow from '../../Components/TopArrow/TopArrow';
import pencil from '../../Assets/icons/pencil.svg';
import Header from '../../Components/Header/Header';
import SelectUserPicture from '../SelectUserPicture/SelectUserPicture';
import ChangePassword from '../ChangePassword/ChangePassword';
import CurrentEmailFiled from '../../Elements/CurrentEmailFiled/CurrentEmailFiled';
import Alert from '../../Components/Alert';
import CheckNewEmailMes from '../../Elements/CurrentEmailFiled/CheckNewEmailMes';
import userSections from './userSections';
import UserInfo from '../../Elements/UserInfo/UserInfo';
import useUserImage from './useUserImage';
import UserImage from './UserImage';
import './style.scss';
import { mainPage } from '../../Utilities/Utilities';
import EventEditForm from '../Company/EventEditForm';
import Modal from '../../Components/Modal/Modal';

const UserData = () => {
  const [modals, setModals] = useState({
    userImg: false,
    userData: false,
    userEmail: false,
    userPass: false,
    organizationData: false,
  });

  const [sentEmail, setSentEmail] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');
  const {
    userID,
    firstName,
    lastName,
    phone,
    email,
    picture,
    currentCompanyName,
  } = useSelector((state) => state.auth);
  const history = useHistory();
  const { img, onDeleteUserImg, getUserImg } = useUserImage(userID, picture);

  const openModal = (modalType) => {
    setModals((prev) => ({ ...prev, [modalType]: true }));
  };

  const closeModal = (modalType) => {
    setModals((prev) => ({ ...prev, [modalType]: false }));
  };

  const clearUser = () => {
    localStorage.removeItem('persist:root');
    history.push('/OneWindow');
    window.location.reload();
  };

  return (
    <>
      <Header
        headerBtn={false}
        pageTitle="Օգտվողի տվյալները"
        btnText="Օգտակար գործիքներ"
      />
      <TopArrow onClick={() => history.push(mainPage)} customClass="TopArrow" />

      <div className="G-user-data-block">
        <UserImage
          img={img}
          onDeleteUserImg={onDeleteUserImg}
          openModal={() => openModal('userImg')}
        />

        {userSections(
          firstName,
          lastName,
          phone,
          email,
          currentCompanyName,
        )?.map(({ title, info, modal }, index) => (
          <div className="user-data user-data-block-solid" key={index}>
            <p className="G-title-p">{title}</p>
            <div className="user-info">
              {info.map((item, idx) => (
                <span key={idx}>{item}</span>
              ))}
            </div>
            <span className="pencil-box" onClick={() => openModal(modal)}>
              <img src={pencil} alt="edit icon" />
            </span>
          </div>
        ))}

        {modals.userImg && (
          <SelectUserPicture
            closeModal={() => closeModal('userImg')}
            img={img}
            getUserImg={getUserImg}
          />
        )}
        {modals.userData && (
          <UserInfo closeModal={() => closeModal('userData')} />
        )}
        {modals.userPass && (
          <ChangePassword closeModal={() => closeModal('userPass')} />
        )}
        {modals.userEmail && (
          <CurrentEmailFiled
            closeModal={() => closeModal('userEmail')}
            setErrorHandler={setErrorHandler}
            setSentEmail={setSentEmail}
          />
        )}
        {modals.organizationData && (
          <Modal
            customClass="L-organization-form from-user-data"
            closeHandler={() => closeModal('organizationData')}
          >
            <EventEditForm />
          </Modal>
        )}

        <Alert description={errorHandler} onClose={setErrorHandler} />
      </div>

      {sentEmail && (
        <CheckNewEmailMes
          onRemoveUserData={clearUser}
          closeModal={setSentEmail}
          currentEmail={sentEmail}
        />
      )}
    </>
  );
};

export default UserData;
