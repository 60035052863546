import React, { useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import Action from '../../../App/Components/Table/Action';
import { dateFormat, tableActionTitle } from '../../../App/Utilities/Utilities';
import { globalActionItems } from '../../Config/tableConfig';
import Forms from './Forms';
import Loading from '../../../App/Components/Loading/Loading';

const EmployeesTable = ({ mainData, loading }) => {
  const [showForm, setShowForm] = useState(false);

  const columnConfig = [
    {
      title: 'Ստորաբաժանում',
      cell: (row) => row.depName,
      customStyle: { maxWidth: 350 },
    },
    {
      title: 'Տաբել N',
      cell: (row) => row.empNo,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Ազգանուն, Անուն, Հայրանուն',
      cell: (row) => row.lastName + '  ' + row.firstName + '  ' + row.patrName,
      customStyle: { maxWidth: 600 },
    },
    {
      title: 'Ընդունվել է',
      cell: (row) => dateFormat(row.hireDate),
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Ազատվել է',
      cell: (row) => (row.fireDate ? dateFormat(row.fireDate) : null),
      customStyle: { maxWidth: 100 },
    },
    {
      title: 'Պաշտոնը',
      cell: (row) => row.positionName,
      customStyle: { maxWidth: 410 },
    },

    {
      title: tableActionTitle,
      cell: (row) => (
        <Action
          row={row}
          handelChange={(value, row) =>
            setShowForm({
              type: value,
              value: row?.employeeID,
            })
          }
          actionItem={globalActionItems}
          modalIsOpen={showForm}
        />
      ),
      customStyle: { maxWidth: 140 },
    },
  ];
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {mainData !== null && mainData && mainData?.length ? (
        <Table customClass="" data={mainData} columnConfig={columnConfig} />
      ) : null}
      <Forms formType={showForm} closeModal={setShowForm} />
    </>
  );
};

export default EmployeesTable;
