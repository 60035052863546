import React, { useState } from 'react';
import Table from '../../../App/Components/Table/Table';
import {
  Commas,
  dataTimeFormat,
  dateFormat,
  removeCommas,
} from '../../../App/Utilities/Utilities';
import NumericFormatFiled from '../../../App/Components/NumericFormat/NumericFormatFiled';
import Tooltip from '../../../App/Components/Tooltip/Tooltip';
import DateComponent from '../../components/dateComponent/DateComponent';
import Alert from '../../../App/Components/Alert';

const AssignPositionsTable = ({
  updateData,
  setUpdateData,
  isRegister,
  setIsDisabled,
  dateOfCalculation,
}) => {
  const [errorMess, setErrorMess] = useState('');
  const handleChangeAmount = (e, targetRow) => {
    const { value, name } = e.target;
    const newValue = removeCommas(value);

    const updatedRows = updateData?.salarySets?.map((row) => {
      if (targetRow.empNo === row.empNo) {
        const updatedRow = {
          ...row,
          [name]: newValue,
        };

        return updatedRow;
      }
      return row;
    });

    setUpdateData((prev) => ({
      ...prev,
      salarySets: updatedRows,
    }));
    setIsDisabled(false);
  };

  const handleDateChange = (date, targetRow) => {
    if (dataTimeFormat(date) < dataTimeFormat(dateOfCalculation)) {
      setIsDisabled(true);
      setErrorMess('Նշանակման ամսաթիվը չի կարող փոքր լինել Հաշվարկի ամսաթիվից');
      return;
    }

    const updatedRows = updateData?.salarySets?.map((row) => {
      if (targetRow.empNo === row.empNo) {
        const updatedRow = {
          ...row,
          setDate: dataTimeFormat(date),
        };
        return updatedRow;
      }
      return row;
    });
    setUpdateData((prev) => ({
      ...prev,
      salarySets: updatedRows,
    }));
    setIsDisabled(false);
  };

  const columnConfig = [
    {
      title: 'Ստորաբաժանում',
      cell: (row) => row.depName,
      customStyle: { maxWidth: 450 },
    },
    {
      title: 'Տաբել N',
      cell: (row) => row.empNo,
      customStyle: { maxWidth: 80 },
    },
    {
      title: 'Ազգանուն, Անուն, Հայրանուն',
      cell: (row) => row.empName,
      customStyle: { maxWidth: 800 },
    },
    {
      title: <Tooltip content="Ընդունման ամսաթիվը">Ընդունման ամս.</Tooltip>,
      cell: (row) => dateFormat(row.hireDate),
      customStyle: { maxWidth: 160 },
    },
    {
      title: (
        <Tooltip content="Ընթացիկ հավելումի կոդը">Ընթ․ հավելումի կոդը</Tooltip>
      ),
      cell: (row) => row.oldSTNo,
      customStyle: { maxWidth: 160 },
    },
    {
      title: <Tooltip content="Ընթացիկ ամսաթիվը">Ընթ. ամսաթիվը</Tooltip>,
      cell: (row) => (row.oldSetDate ? dateFormat(row.oldSetDate) : ''),
      customStyle: { maxWidth: 130 },
    },
    {
      title: 'Ընթացիկ հաստիքը',
      cell: (row) => Commas(row.oldSalary),
      customStyle: { maxWidth: 150 },
    },
    {
      title: <Tooltip content="Նշանակման ամսաթիվը">Նշանակման ամս.</Tooltip>,
      cell: (row) => (
        <DateComponent
          value={dateFormat(row.setDate)}
          onChange={(date) => handleDateChange(date, row)}
          name="setDate"
          requiredFiled={false}
          disabled={row.error}
        />
      ),
      customStyle: { maxWidth: 140 },
    },

    {
      title: 'Նոր հաստիքը',
      cell: (row) =>
        row.error ? (
          <p title={row.errorMessage}>{row.errorMessage}</p>
        ) : (
          <NumericFormatFiled
            value={row.sAmount}
            allowNegative={true}
            name="sAmount"
            changeHandler={(e) => handleChangeAmount(e, row)}
            inputTitle=""
          />
        ),
      customStyle: { maxWidth: 150, overflow: 'hidden' },
    },
  ];

  const modifiedSalarySetData = updateData?.salarySets?.map((row) => ({
    ...row,
    rowClass: row.error ? 'row-has-red-bg' : '',
  }));
  return (
    <>
      {modifiedSalarySetData !== null && modifiedSalarySetData?.length ? (
        <Table
          customClass="L-assign-positions-table G-table-has-scroll"
          data={modifiedSalarySetData}
          columnConfig={columnConfig}
        />
      ) : (
        <p className="L-assign-positions-mess">{updateData?.message}</p>
      )}

      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default AssignPositionsTable;
