import React from 'react';
// import './style.scss';
import MenuBar from '../menuBar/MenuBar';

import RenderMaterialValuesPage from '../RenderMaterialValuesPage/RenderMaterialValuesPage';

const MaterialValuesWrap = ({ children }) => {
  return (
    <div className="G-salary-component">
      <MenuBar />
      <RenderMaterialValuesPage children={children} />
    </div>
  );
};

export default MaterialValuesWrap;
