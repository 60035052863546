import React, { useState } from 'react';
//import './style.scss';
import HelpBtn from '../../../App/Components/HelpBtn/HelpBtn';
import { useLocation } from 'react-router-dom';
import SalaryIcon from '../../../App/Assets/homeIcons/salary.png';
const RenderMaterialValuesPage = ({ children }) => {
  const [text, setText] = useState(null);
  let location = useLocation();
  const mainPage = location.pathname === '/MaterialValuesDashboard';

  // const helpHandler = () => {
  //   return HelperTextArr?.map(({ path, text }) => {
  //     return path === location.pathname ? setText(text) : path;
  //   });
  // };

  return (
    <div className="salary-main-container">
      {mainPage ? (
        <div className="salary-logo-block">
          <div className="salary-logo-wrap">
            <img src="" alt="" />
          </div>
          <h3>Նյութական արժեքներ</h3>
        </div>
      ) : null}
      <HelpBtn
      //helpHandler={helpHandler}
      />
      <div className="G-main-container">{children}</div>
    </div>
  );
};

export default RenderMaterialValuesPage;
