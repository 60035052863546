import React, { useEffect, useState } from 'react';
import MakeAdditionalSaveTable from './MakeAdditionalSaveTable';
import MakeAdditionalSaveHeader from './MakeAdditionalSaveHeader';
import MakeAdditionalSaveAction from './MakeAdditionalSaveAction';
import useMakeAdditionalSave from '../../hooks/useMakeAdditionalSave';
import './style.scss';
import {
  dataTimeFormat,
  filterObjKeys,
} from '../../../App/Utilities/Utilities';
import Alert from '../../../App/Components/Alert';
const MakeAdditionalSave = ({ handleClose, rowValues }) => {
  const {
    getMakeAdditionalSave,
    makeAdditionalData,
    getIsExecuteData,
    additionalSaveData,
    setAdditionalSaveData,
    addMakeAddition,
    isLoading,
    errorMess,
    setErrorMess,
  } = useMakeAdditionalSave();
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [query, setQuery] = useState({
    salaryDate: '',
    stNo: '',
    stName: '',
    formulaID: '',
    formulaName: '',
    coeff: '',
    empNo: rowValues?.empNo,
    allMakeSalaries: false,
    amount: 0,
  });
  const keysToRemove = ['formulaName', 'stName'];
  const modifyObj = filterObjKeys(query, keysToRemove);
  const afterSaveData = {
    salaryDate: dataTimeFormat(query.salaryDate),
    empNo: query.empNo,
  };
  useEffect(() => {
    getMakeAdditionalSave(rowValues?.empNo);
  }, [rowValues?.empNo]);

  useEffect(() => {
    if (query.stNo && query.formulaID) {
      setIsDisabledBtn(false);
    } else {
      setIsDisabledBtn(true);
    }
  }, [query]);

  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      salaryDate: makeAdditionalData?.salaryDate,
    }));
  }, [makeAdditionalData?.salaryDate]);

  useEffect(() => {
    if (query.empNo) {
      getIsExecuteData(query);
    }
  }, [query.salaryDate]);

  const onExecuteHandler = async () => {
    try {
      await getIsExecuteData(modifyObj);
      setIsDisabledBtn(true);
    } catch (err) {
      console.error('An error occurred:', err);
      setIsDisabledBtn(false);
    }
  };

  const resetQuery = () => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      stNo: '',
      stName: '',
      formulaID: '',
      formulaName: '',
      coeff: '',
      empNo: rowValues?.empNo,
      allMakeSalaries: false,
      amount: 0,
    }));
  };

  return (
    <>
      <MakeAdditionalSaveHeader data={rowValues} />
      <MakeAdditionalSaveAction
        makeAdditionalData={makeAdditionalData}
        setAdditionalSaveData={setAdditionalSaveData}
        query={query}
        setQuery={setQuery}
        onExecuteHandler={onExecuteHandler}
        isDisabledBtn={isDisabledBtn}
      />
      <MakeAdditionalSaveTable
        mainData={additionalSaveData?.makes}
        afterSaveData={afterSaveData}
        query={query}
        resetQuery={resetQuery}
        addMakeAddition={addMakeAddition}
        isLoading={isLoading}
      />
      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default MakeAdditionalSave;
